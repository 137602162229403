import { useMemo, useCallback, useState } from "react";
import "./App.css";
import { Input } from "./components/Input";

const App = () => {
  const [input, setInput] = useState(0);
  const [result, setResult] = useState(0);

  const onChange = useCallback((e) => {
    if (e.target.value > 0) {
      setInput(e.target.value);
    }
  }, []);

  useMemo(() => {
    let acc = 1;
    for (let i = 1; i <= input; i++) {
      acc *= i;
    }
    setResult(acc);
  }, [input]);

  return (
    <div className="App">
      <header className="App-header">
        <p>Calcule o fatorial de:</p>
        <Input input={input} setOnChange={onChange} />
        <p>{result}</p>
      </header>
    </div>
  );
};

export default App;
